#root .p-button {
  border-radius: 5px;
}

.p-unselectable-text.p-tabview-selected.p-highlight{
  border-bottom: 3px solid;
}

.image-text {
  margin: 0.25rem;
  width: 60px;
}

.task-status{
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.task-status-new{
  background-color: #b3e5fc;
  color: #23547b;
}

.task-status-active{
  background-color: #feedaf;
  color: #8a5340;
}

.task-status-start{
  background-color: #eccfff;
  color: #694382;
}

.task-status-verification{
  background-color: #ffffff;
  color: #dd0909;
}

.task-status-payout{
  background-color: #ffe9cd;
  color: #c68337;
}

.task-status-payment{
  background-color: #dd0909;
  color: #ffffff;
}

.task-status-confirm{
  background-color: #c8e6c9;
  color: #256029;
}

.task-status-reject{
  background-color: #ffcdd2;
  color: #c63737;
}

.p-tabview .p-tabview-nav li {
  font-size: 12px;
}

.p-tabview .p-tabview-nav-btn.p-link {
  background: #ffffff9c !important;
  width: 2rem !important;
}

.p-tabview-nav-next {
  right: -5px !important;
}

.p-tabview-nav-prev {
  left: -5px !important;
}

.p-tabview .p-tabview-panels {
  padding: 1rem 0rem !important;
}

.p-fileupload .p-fileupload-buttonbar,
.p-fileupload .p-fileupload-content {
  border: 1px solid #dfdfdf !important;
}


.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border-color: #ffcc75 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-highlight {
  background: #ffffff !important;
  border-color: #ffa307 !important;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px !important;
  height: 12px !important;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #ffa307 !important;
}

.p-rating .p-rating-icon.pi-star-fill {
  color: #f7a309 !important;
}

.custom-input-text{
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #ede8e9;
  border-radius: 8px;
  padding: 12px 18px;
}

.custom-input-text label {
  font-weight: normal;
  font-size: 12px;
  color: #908e8e;
}

.custom-input-text input{
  font-weight: bold;
  font-size: 14px;
  color: #3a3335;
  background: #fff;
  height: 24px;
  font-family: MontserratRegular;
  font-style: normal;
  line-height: 21px;
  letter-spacing: -0.1px;
  border: none;
  padding-left: 0px;
}

.custom-input-text > .p-multiselect{
  font-weight: bold;
  font-size: 14px;
  color: #3a3335;
  background: #fff;
  font-family: MontserratRegular;
  font-style: normal;
  line-height: 18px;
  letter-spacing: -0.1px;
  border: none;
  padding-left: 0px !important;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  display: none;
}

.custom-multiselect .p-multiselect-label-container .p-multiselect-label{
  padding: 0px 15px 0px 0px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext{
  padding: 0.25rem;
  font-size: 14px;
  border-color: #d7d7d7;
  font-family: MontserratRegular;
}

.p-multiselect:not(.p-disabled).p-focus {
  border: none !important;
  box-shadow: none !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  white-space: break-spaces;
  max-width: fit-content;
  min-width: 100%;
}